import React from 'react'

import Typist from '@components/common/typist'
import * as Icons from '@components/scrollytelling/reports/infographic-icons'
import Link from '@components/common/link'
// import TNHLogo from '@components/scrollytelling/reports/tnh-logo'
// import TimelineComponent from '../../components/scrollytelling/annual-report/vertical-timeline'

const ChapterHeader = ({ title, subtitle, textShadow, quote }) => {
	return (
		<div className={'px-5 flow flex flex-col justify-center items-center'}>
			<h1 style={{ textShadow: textShadow ? '2px 2px 15px rgba(0,0,0,0.6)' : 'none' }} className={'text-white text-center md:text-7xl lg:text-8xl xl:text-9xl'}>
				{title}
			</h1>
			{/* <p style={{ textShadow: textShadow ? '2px 2px 15px rgba(0,0,0,0.6)' : 'none' }} className={'text-white text-4xl mb-3 text-center'}>
				{subtitle}
			</p> */}
			<h2 className={'z-50 px-3 py-2 inline-block mx-auto text-white bg-burgundy text-center text-xl sm:text-5xl'} style={{ marginTop: '2.8rem' }}>
				{quote.length > 1 ? quote[0] : quote}
				<Typist texts={quote} />
			</h2>
		</div>
	)
}

const InlineYoutubeVideo = ({ caption, src }) => {
	return (
		<div className={'relative h-0 pt-sixteenbynine mx-auto py-12 w-full mb-20'}>
			<iframe
				className={'absolute top-0 left-0 w-full h-full'}
				width='100%'
				height='auto'
				src={src}
				title='YouTube video player'
				frameborder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			/>
			{caption && (
				<p style={{ background: '#f2f2f2', color: '#63666a' }} className={'text-gray-600 p-4 mt-12'}>
					{caption}
				</p>
			)}
		</div>
	)
}

const InlineVideo = ({ caption, fileName, autoPlay, loop }) => {
	return (
		<div className={'sm:mx-0 md:-mx-10 lg:-mx-72 xl:-mx-96 py-12'}>
			<video autoPlay={autoPlay || true} muted={autoPlay || muted || false} loop={loop || true} playsInline>
				<source src={`/images/reports/annual-report-2021/${fileName}`} />
			</video>
			{caption && (
				<p style={{ background: '#f2f2f2', color: '#63666a' }} className={'text-gray-600 p-4'}>
					{caption}
				</p>
			)}
		</div>
	)
}

const InlineImage = ({ caption, fileName, wide }) => {
	return (
		<div className={`py-12 ${wide ? 'sm:mx-0 md:-mx-52 lg:-mx-84 xl:-mx-96' : 'sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}`}>
			<img src={`/images/reports/annual-report-2021/${fileName}`} />
			{caption && (
				<p style={{ background: '#f2f2f2', color: '#63666a' }} className={'text-gray-600 p-4'}>
					{caption}
				</p>
			)}
		</div>
	)
}

const InlineAudio = ({ externalLink, externalLinkText, fileName, type }) => {
	return (
		<div className={'py-12 w-full mx-auto text-center'}>
			<audio controls className={'w-full mb-8'}>
				<source src={require('../../audio/reports/annual-report-2021/' + fileName).default} type={type || 'audio/mp3'} />
				Your browser does not support the audio element.
			</audio>
			<div className={'mx-auto'}>
				<Link href={require('../../audio/reports/annual-report-2021/' + fileName).default}>Download audio file</Link>
				{externalLinkText && externalLink && (
					<>
						<span className={'px-3'}>or</span>
						<Link href={externalLink}>
							<span>{externalLinkText}</span>
						</Link>
					</>
				)}
			</div>
		</div>
	)
}

const BlockImage = ({ fileName }) => {
	return (
		<div>
			<img src={`/images/reports/annual-report-2021/${fileName}`} />
		</div>
	)
}

const InlineTypist = ({ text, textAlign }) => {
	return (
		<h1 className={`z-50 w-full inline-block mx-auto text-burgundy text-center text-2xl sm:text-5xl mb-8 ${textAlign || 'text-center'}`}>
			{/* {text.length > 1 ? text[0] : text} */}
			<Typist texts={text} />
		</h1>
	)
}

const InlineQuote = ({ person, text }) => {
	return (
		<>
			<blockquote style={{ marginTop: '3rem', marginBottom: '3rem' }} className={'text-2xl sm:text-4xl sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}>
				{text} <span className={'block pt-4 text-gray-500 text-3xl'}>– {person}</span>
			</blockquote>
		</>
	)
}

const MarginBreakContainer = ({ children }) => {
	return <div className={'py-16 margin-break'}>{children}</div>
}

const CHAPTERS = [
	{
		title: 'Welcome',
		id: 'welcome',
		backgroundImage: 'welcome',
		className: 'bg-gray-300',
		htmlContent: <ChapterHeader title={'Welcome'} subtitle={'Setting the foundations for growth'} quote={['Here’s what we achieved.']} />,
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['Here’s what we achieved.']} />
				<p>
					After years of creating the building blocks of a successful media non-profit, The New Humanitarian is now reaping the benefits of previous
					investments. 2021 allowed TNH to consolidate successes to date and set the foundations for future growth, most notably with the development of our new
					5-year strategy.
				</p>
				<p>
					We proved that less is more: We reduced our editorial output to allow our editors more time to produce more impactful, creative and distinctive
					reporting, all while maintaining our audience significantly above 2019 levels (as with other news media, audience levels in 2020 were exceptionally
					high due to interest in COVID-19).
				</p>
				{/* <div className={'mx-auto my-5 pt-5'}>
					<TNHLogo />
				</div> */}
				<h2>What we did in 2021</h2>
				<MarginBreakContainer>
					<div className={'gap-10 grid grid-cols-1 sm:grid-cols-3 justify-center items-center sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Clipboard />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								360 reports
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Star />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								6 investigations
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Trophy />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								8 awards and nominations
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Newspaper />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								280,000 monthly readers
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.SocialMedia />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								23,904 new followers on social media for a total of 188,000
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Youtube />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								515,500 views on Youtube
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Podcast />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								100,836 podcast downloads
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Group />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								637 paying members
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Globe />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								135 territories covered
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Handshake />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								16 new or renewed funding contracts and a total of 20 donors
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Staff />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								5 new staff hired
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Microphone />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								More than 20 high-level speaking engagements
							</h3>
						</div>
					</div>
				</MarginBreakContainer>
				<p>
					Our noteworthy journalism included continued coverage of the COVID-19 pandemic and reporting from hard-to-reach areas, including Afghanistan and
					Ethiopia. We published several impactful investigations – including a follow-up on our 2020 revelation of accusations of sexual abuse and exploitation
					of women by Ebola aid workers in the Democratic Republic of Congo.
				</p>
				<p>
					We continued our popular Rethinking Humanitarianism series and launched a second season of the Rethinking Humanitarianism podcast. We led an important
					conversation in the media sector on decolonising journalism about the Global South, while continuing to build our clout and visibility with
					participation in major industry events and distribution partnerships with mainstream media.
				</p>
				<p>
					We continued to strengthen our financial outlook and operations: thanks to continued support from our donors, we renewed several multi-year
					partnerships; increased our budget despite uncertainty linked to COVID-19; and continued to build up our reserves, equivalent to two months of
					operating expenditures.
				</p>
				<p>
					Finally, we sought to improve morale among staff after two long years of COVID, and reinforced our team, including adding specific resources for
					people and culture, podcasting and multimedia, among others.
				</p>
			</div>
		),
	},
	{
		title: 'Foreword',
		id: 'foreword',
		backgroundImage: 'foreword',
		className: 'bg-gray-200',
		htmlContent: <ChapterHeader textShadow title={'Foreword'} subtitle={'Building better journalism'} quote={['Building better journalism.']} />,
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['Building better journalism.']} />
				<p>
					As we write this, Russian bombs are falling over Ukraine in what is fast becoming the most devastating conflict Europe has seen since World War II.
				</p>
				<p>
					The conflict has rightly garnered the world’s attention, but it has also made blatantly clear how little attention humanitarian crises in other parts
					of the world receive in comparison.
				</p>
				<p>There are many Ukraines happening around the world – millions of people in Syria, Palestine, and beyond have suffered a similar fate.</p>
				<p>
					But refugees fleeing Ukraine have blond hair and blue eyes – “they are just like us,” many media reported – “civilised people”, unlike those in Iraq
					and Afghanistan, other commentators noted.
				</p>
				<p>
					At The New Humanitarian, we do not believe that some human lives are more deserving than others, and have dedicated our work to ensuring that all
					stories of suffering receive attention.
				</p>
				<p>And in 2021, there were many.</p>
				<p>
					As the world tried to emerge from the COVID-19 pandemic, a number of new challenges – the Taliban takeover of Afghanistan; a coup in Myanmar; an
					earthquake in Haiti; a new round of violence between Israel and the territory it occupies in Gaza; and an intensifying conflict in Ethiopia –
					compounded existing and forgotten crises around the world, including the growing effects of climate change.{' '}
				</p>
				<p>
					In the face of these multiple crises, the world has been forced to think more radically about how it tackles rising humanitarian needs and global
					challenges sustainably and effectively over the long-term.
				</p>
				<p>
					The media sector that reports on these crises has also faced tough questions about its future, amid a growing demand for decolonised, meaningful
					participatory media spaces with different power dynamics and an increasing expectation of journalism as a constructive force for good.
				</p>
				<p>
					So we too are thinking more radically about our way forward in this changing landscape; about what it means to be a truly global news organisation
					with universalist values; and about the long-term future we want to see.
				</p>
				<InlineImage
					fileName={'foreword-inline.jpg'}
					caption={
						'A young boy sits on volcanic rock in Kanyaruchinya, just north of Goma, following the eruption of Nyiragongo. Lava flows razed fields and submerged thousands of homes before stopping just short of Goma’s international airport. (Ley Uwera/TNH)'
					}
				/>
				<p>
					In 2021, we developed a new 5-year strategy that is ambitious in scope and transformative in orientation. Our vision is to build a viable model of
					decolonised, impact-focused and sustainable journalism on the international stage.
				</p>
				<p>
					We spent months looking inward to try to understand what decolonising our journalism means in practice: de-Westernising our lens; being guided by the
					communities we cover; and being unafraid to challenge power dynamics of all kinds. We emerged from that strategy process having found our voice as an
					advocate for more anti-racist, inclusive, and community-driven journalism, and with a clearer focus on the two areas where we feel we can bring most
					value in the media landscape: shining a light on forgotten crises and examining aid policy.
				</p>
				<p>
					With this renewed clarity of mission, we became bolder in how we presented ourselves to the world, leading conversations about the decolonisation of
					international media coverage; amplifying local voices with even more intentionality; and creating platforms for discussion of existential questions
					about the way aid is delivered.
				</p>
				<p>
					Through our articles, events, podcasts and speaking engagements – sometimes on the global stage; other times behind closed doors – we used our voice
					to act as facilitators for increasingly important conversations about the world we live in; and to communicate our values to the world loud and clear,
					right down to who we{' '}
					<Link href={'https://assets.irinnews.org/s3fs-public/tnh_principles_for_accepting_financial_donations_-_updated.pdf'}>will and won’t</Link> accept
					money from.
				</p>
				<p>
					We used different approaches to engage a range of audiences in the stories of people affected by crises, from drone footage to interactive
					storytelling, from illustrations by local artists to first-person testimonies.
				</p>
				<p>
					We also set the foundations for future growth. We created a new role of Chief Operating Officer, charged with developing systems to allow us to
					optimise and scale our activities. We hired staff to allow us to grow into new areas of work – from people and culture to podcasting. And we renewed a
					series of multi-year grants, developed a strategy for an individual giving programme, and invested more resources in revenue diversification.
				</p>
				<p>
					All of this is a sign of our maturity: of our confidence about our place in the world; of our courage to think long-term and do things differently.
				</p>
				<p>
					The world has changed drastically in the last two years. We must stay ahead of that change to continue to be effective and impactful in our mission to
					put world-class journalism at the service of the most vulnerable people in the world.
				</p>
				<p>
					We will work to deliver on this vision of transformation in the years ahead: transformation in the way journalism is produced; transformation in the
					way the aid sector operates; transformation in the lives of those affected by crises. As always, we welcome you to join us in this important work.
				</p>
				<div className={'grid grid-cols-1 gap-x-16 sm:grid-cols-2 mb-6'}>
					<div className={'flex flex-col'}>
						<div className={'w-72 h-96'} style={{ background: 'url(/images/reports/annual-report-2021/paula-fray.jpg)', backgroundSize: 'cover' }} />
						<p className="pt-2">
							<strong>Paula Fray</strong>
							<br />
							President
						</p>
					</div>
					<div className={'flex flex-col'}>
						<div className={'w-72 h-96'} style={{ background: 'url(/images/reports/annual-report-2021/heba-aly.jpg)', backgroundSize: 'cover' }} />
						<p>
							<strong>Heba Aly</strong>
							<br />
							CEO
						</p>
					</div>
				</div>
			</div>
		),
	},
	{
		title: 'Our journalism',
		id: 'our-journalism',
		backgroundImage: 'our-journalism',
		className: 'bg-gray-300',
		htmlContent: (
			<ChapterHeader
				title={'Our journalism'}
				subtitle={'Decolonised, impact-focused, sustainable.'}
				quote={['In-depth, field-based perspectives on humanitarian crises.']}
			/>
		),
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['Providing in-depth, field-based perspectives on humanitarian crises ']} />
				<p>
					When political turmoil thrust humanitarian needs into mainstream headlines, we pushed beyond those headlines to provide distinctive, aid-focused
					coverage looking at the roots of those crises and ways forward. As the Taliban took control of Afghanistan and silenced many Afghan voices, we relied
					on the remote reporting skills honed in the pandemic to rebuild our shattered network of sources and journalists and ensure their voices were heard.
					When tens of thousands of people, many of them Haitian, arrived on the southern US border, we were among the first to report from the Darién Gap,
					between Colombia and Panama, on the perilous migration route they had taken. And long after the military takeover of Myanmar disappeared from the news
					cycle, we continued to speak with local sources and tap local contributors to highlight the deepening challenges faced by civilians and aid workers,
					even as physical access and communications were severely restricted.
				</p>
				<p>
					We unearthed signs of progress, too. We looked at how COVID-19 raised awareness of needs within the Navajo Nation’s Indigenous community in the United
					States, and funding to help meet those needs. We dug deep into data to understand the great progress Bangladesh has made in reducing deaths from
					storms, even while a warming planet amps up the ferocity of those storms. And we reported on grassroots efforts to make a difference in the lives of
					communities experiencing humanitarian crises, from women working to ease ethnic tensions in South Sudan to{' '}
					<Link
						href={
							'https://www.thenewhumanitarian.org/news-feature/2021/3/24/indigenous-groups-in-amazon-race-against-time-on-vaccines?utm_source=The+New+Humanitarian&utm_campaign=18af3c1c1e-EMAIL_CAMPAIGN_2020_12_11_Weekly_COPY_01&utm_medium=email&utm_term=0_d842d98289-18af3c1c1e-75468313'
						}
					>
						Indigenous Amazon
					</Link>{' '}
					groups’ efforts to ensure vaccine access for their communities.
				</p>
				<InlineImage
					fileName={'our-journalism-inline.jpg'}
					caption={`A nurse at a medical centre in the Shipibo-Konibo Indigenous community in Peru's Ucayali region monitors the health of a mother and her daughter amid the COVID-19 pandemic. (Sebastián Castañeda/TNH)`}
				/>
				<h2>Shining a spotlight on forgotten stories and emerging trends</h2>
				<p>
					Despite obstacles to access, the ongoing battle for readers’ attention, and the cost and personal risks of on-the-ground reporting, we held fast to
					our moral commitment to pay consistent attention to crises often overlooked by mainstream media. In Burkina Faso, it took a chartered plane and
					carefully devised security plans to report from the town of Djibo – where, we were told, we were the first international journalists to visit in five
					years. Once there, in an exclusive report we revealed secret peace deals being pursued with jihadists. In Sudan, we needed months of research and a
					trip by donkey across rugged terrain to enable our reporters – among only a handful of journalists to visit mountainous rebel-held territory in a
					decade, residents said – to explore how Darfur had gone from a global cause to a forgotten crisis, one in which 1.5 million people remain in
					displacement camps and new violence has forced hundreds of thousands of others from their homes. And after six months of building contacts and trust
					with sources on the ground, we spent time in northeastern Nigeria, speaking with former Boko Haram fighters who were part of a secretive government
					programme to return them to civilian life, and their neighbours, who were weighing whether they could forgive and move on.
				</p>
				<InlineImage
					fileName={'our-journalism-inline-two.jpg'}
					caption={
						'In Darfur’s rebel-held mountains, boyish-looking rebels stand guard outside the office of the SLA-AW rebel group commander, Gaddura. (Philip Kleinfeld/TNH)'
					}
				/>
				<h2>Casting a critical eye over the emergency aid sector</h2>
				<p>
					Our coverage spurred discussions and reflection within the humanitarian response sector, among governments and other donors who fund it, and in the
					communities it serves. Through surveys, data analysis, and discussions with sources throughout the sector, we examined how aid could be distributed in
					a more climate-conscious way; whether humanitarian organisations had made good on vows to improve racial justice within their ranks; and on the pace
					and appetite for change in the face of new realities. And our platforms remained a safe space for myriad voices to question long-held assumptions and
					ways of working, from aid workers in Myanmar asking whether the idea of neutrality is outdated, to refugees suggesting how they could have{' '}
					<Link href={'https://www.thenewhumanitarian.org/opinion/2021/12/15/give-refugees-a-voice-on-our-own-futures'}>a real say</Link>
					in the multilateral decision-making that shapes their futures. Our exclusive reporting uncovered a mystery donor to humanitarian aid for Yemen (hint:
					look no further than the key combatants), and donor discontent over the UN’s handling of aid to Ethiopian refugees in Sudan. And we exposed the
					abusive and deadly effects of EU and Libyan migration policies.
				</p>
				<h2>Places we reported from in 2021</h2>
				<InlineImage fileName={'our-journalism-inline-three.png'} />
				{/* <InlineImage fileName={'our-journalism-inline-four.png'} />
				<InlineImage fileName={'our-journalism-inline-five.png'} />
				<InlineImage fileName={'our-journalism-inline-six.png'} />
				<InlineImage fileName={'our-journalism-inline-seven.png'} /> */}
			</div>
		),
	},
	{
		title: 'Our impact',
		id: 'our-impact',
		backgroundImage: 'our-impact',
		className: 'bg-gray-200',
		htmlContent: (
			<ChapterHeader
				textShadow
				title={'Our impact'}
				subtitle={'Informing the world’s response to crises.'}
				quote={['How our journalism creates real impact.', 'Informing the world’s response to crises.']}
			/>
		),
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['How our journalism creates real impact.']} />
				<p>
					The New Humanitarian’s reporting informs the prevention and response to humanitarian crises by contributing to better decision-making; accountability
					and transparency; and greater awareness. In so doing, we seek to contribute to more effective and accountable humanitarian action.
				</p>
				<p>
					We monitor the impact of our journalism through our reach, reference to our work in online and offline spaces, our ability to influence the wider
					media narrative, feedback from our readers, audience surveys, independent evaluations, and examples of tangible, real-world impact prompted by our
					reporting. In many cases, simply bringing awareness to an overlooked issue can result in positive change.
				</p>
				<p>
					In 2021, our stories informed goverment strategy meetings; prompted major international NGOs to re-evaluate their measures for the prevention of
					sexual abuse and exploitation; and influenced the thinking of a former UN refugee agency official who published a critical report on the agency’s use
					of biometrics in emergency response.
				</p>
				<p>
					We continue to see evidence that we are fulfilling our mission of informing the world’s response to crises. Here are four examples of the impact of
					our reporting.
				</p>
				<h2>Triggering Arabic translations for Syria war crimes tribunals</h2>
				<p>
					In October 2021, a German judge was preparing to read the verdict in a historic trial: the first seeking to convict members of Syrian President Bashar
					al-Assad’s government of war crimes, under universal jurisdiction, whereby grave crimes can be prosecuted anywhere. But justice was happening far from
					where the crimes were committed, and in a language the victims didn’t speak. In a{' '}
					<Link href={'https://www.thenewhumanitarian.org/special-report/2021/10/21/Syrian-war-crimes-trial-Germany-justice-lost-in-translation'}>
						special report
					</Link>
					, we explored this “outreach gap”, when justice fails to include the people it is ostensibly meant to serve.
				</p>
				<InlineImage
					fileName={'our-impact-inline.jpg'}
					caption={'An illustration depicting the Koblenz court on the left-hand side, and a street in Syria on the other. (Kylee Pedersen/TNH)'}
				/>
				<p>
					Human Rights Watch said that it is a “truly brilliant piece on the importance of outreach in such trials” and{' '}
					<Link href={'https://www.hrw.org/news/2022/01/19/syria-german-trial-wartime-atrocities-opens'}>referenced</Link> it in their work.
				</p>
				<p>
					The transitional justice news site Justice.info linked to our report in its own{' '}
					<Link href={'https://www.justiceinfo.net/en/86043-prosecution-raslan-was-either-powerless-or-he-wasnt.html'}>coverage</Link>. Its deputy editor,
					Franck Petit, who is an expert on the subject and the former head of the outreach programme at the Extraordinary African Chambers in Senegal, a
					tribunal established to try international crimes committed in Chad between 1982 and 1990 (with an outreach programme considered by many to be a model
					for how this should be done), <Link href={'https://twitter.com/FranckF_P/status/1451128375859466242'}>shared</Link> it and said it was a “timely and
					well-documented piece”. We also heard from an academic and lawyer based at the University of Glasgow who referenced the article in an academic journal
					article she is writing in which she evaluates chances and challenges of universal jurisdiction proceedings.
				</p>
				<p>
					Following publication, an organisation that is filing many of the Syria universal jurisdiction complaints on behalf of or with Syrian groups got in
					touch with our reporter to discuss how to incorporate more and better outreach as the trials go forward. Similarly, a German Member of the European
					Parliament was in touch with our reporter to discuss her research.
				</p>
				<p>
					Demonstrating the importance of this work, the Syria Justice and Accountability Centre then{' '}
					<Link href={'https://syriaaccountability.org/updates/2021/11/10/sjac-resumes-arabic-language-trial-reports/'}>announced</Link> that it would resume
					full Arabic translations of the trials as well as their backlog of reports due to new funding opportunities.
				</p>
				<h2>Prompting investigations into a sex-for-food scheme in Burkina Faso</h2>
				<p>
					In one of six investigations we published in 2021, we{' '}
					<Link href={'https://www.thenewhumanitarian.org/investigations/2021/7/26/sex-for-food-aid-allegations-in-burkina-faso'}>revealed claims</Link> from
					displaced women in Burkina Faso that local men – some of them community leaders – demanded sex in exchange for humanitarian assistance.
				</p>
				<InlineImage
					fileName={'our-impact-inline-two.jpg'}
					caption={
						'Women walking out of a compound in the northern town of Kaya after speaking to The New Humanitarian about how they were solicited for sex while trying to get food aid. (Sam Mednick/The New Humanitarian)'
					}
				/>
				<p>
					Most of the women we spoke to said they knew of other women who were propositioned in the same way and aid workers and government officials said they
					knew of similar accounts, suggesting the abuse was widespread. However, seven large international aid agencies and NGOs we spoke to said they had
					received no claims involving the registration process of displaced people that makes them eligible for assistance.{' '}
				</p>
				<p>
					Our investigation also highlighted the slow pace of progress by agencies to prevent sexual abuse and exploitation. Following publication, the head of
					the World Food Programme (WFP) in Burkina Faso sent an email to the organisation’s donors to reiterate the efforts it had made in this regard. WFP and
					UNICEF also pledged to investigate whether any of the accused men had worked for international organisations.
				</p>
				<p>
					Our report travelled widely at the local level, with nearly 20% of its readers residing within Burkina Faso. Local and international media followed
					the story, including VOA Afrique, which interviewed our{' '}
					<Link href={'https://www.voaafrique.com/a/au-faso-1-3-millions-de-d%C3%A9plac%C3%A9s-par-la-situation-s%C3%A9curitaire/5981797.html'}>CEO</Link> and{' '}
					<Link href={'https://www.voanews.com/a/africa_sex-food-aid-scandal-unfolding-burkina-faso/6209639.html'}>reporter</Link>, and conducted its{' '}
					<Link href={'https://minute.bf/kongoussi-des-femmes-deplacees-echangent-le-sexe-contre-la-nourriture/'}>own investigations</Link>. These
					investigations put{' '}
					<Link href={'https://www.voanews.com/a/burkina-faso-s-sex-for-food-aid-scandal-draws-government-denial-lawsuit/6231312.html'}>pressure</Link> on the
					minister for humanitarian affairs to address the problem in a press conference.
				</p>
				<p>
					The story also led to positive impact within the UN, which reportedly increased support towards the protection of people in humanitarian emergencies,
					most notably with the hire of a PSEA (Protection from Sexual Exploitation and Abuse) Coordinator in Burkina Faso.
				</p>
				<h2>Driving decision-makers to think differently about the future of crisis response</h2>
				<p>
					Discussions on our flagship <Link href={'https://www.thenewhumanitarian.org/podcast'}>Rethinking Humanitarianism podcast</Link> and associated
					coverage have shifted the conversation within donor circles about key issues on the nature of aid.
				</p>
				<p>
					Described as a “must-listen to” of the humanitarian community; “consistently interesting and informative"; and a “rare podcast I come to with pen and
					paper”, the podcast has inspired decision-makers to think differently. “It’s really helping move forward the debate about how to do humanitarianism
					better,” said the head of an NGO that works on humanitarian reform.
				</p>
				<p>
					An <Link href={'https://www.thenewhumanitarian.org/podcast/2020/1/6/rethinking-humanitarianism-decolonising-aid'}>episode</Link> on the decolonisation
					of aid, downloaded more than 10,500 times in 2021, prompted many further conversations across the sector. “The episode spread widely and was listened
					to with interest until now – I have not seen that on a topic of this kind before,” the episode’s interviewee, Syrian physician Tammam Aloudat, told
					us. “I have been contacted by dozens of people: students, professionals, academics, NGO executives, academics, and others, to discuss the podcast or
					to invite me to speak.” Following the episode, he was invited to give lectures at universities including Columbia, Harvard, Johns Hopkins, and King’s
					College London, among others, and to speak to NGOs including Médecins Sans Frontières, the Norwegian Refugee Council, and UNICEF, as well as to
					co-author book chapters and write articles on the topic. He described the episode as “one of the key moments of the debate on decolonising
					humanitarianism”.
				</p>
				<p>
					Following that episode, we conducted a{' '}
					<Link href={'https://www.thenewhumanitarian.org/podcast/2021/10/13/rethinking-humanitarianism-diversity-in-the-aid-sector'}>survey</Link> on progress
					made towards diversity, equity, and inclusion within the aid sector since the renewed push for racial justice. We were later invited to brief the Good
					Humanitarian Donorship, a grouping of 42 government donors about the topic, in what was reportedly the first discussion of the topic in a donor forum.
					The report from that meeting is reportedly what one major donor needed to kick-start internal discussions on the topic of decolonising aid.
				</p>
				<p>
					In early 2022, these conversations culminated in a private convening, hosted by The New Humanitarian, in which donors and policymakers came together
					with local civil society leaders and racial justice advocates for a frank exchange. ”I have never been in nor seen a meeting like that, specifically
					with institutions, government representatives and donors there in numbers. That was a first, and I have to say it felt more meaningful,” said one
					participant. “I came away with a lot of new insights and possible resources to consider as we further our journey on decolonisation,” said the CEO of
					a philanthropic foundation who attended the convening. Several participants have since held conversations within their own institutions as a result of
					what they heard at the TNH event, including donors who met with their legal and finance teams to discuss how they overcome challenges to funding more
					locally.{' '}
				</p>
				<p>
					In a sign of the momentum developed around the topic, thanks to our efforts and those of others, the head of one international NGO said: “Decolonising
					aid has truly become a critical term I hear everywhere.”
				</p>
				<p>
					The UK Parliament invited our CEO to <Link href={'https://parliamentlive.tv/event/index/6142c328-9b9f-4c8a-b0ce-8ec3fe4ff179'}>testify</Link> about
					the existential questions confronting the aid industry at an inquiry into the philosophy of aid. Her comments were cited several times in the
					subsequent recommendations made to the UK government.
				</p>
				<p>
					A{' '}
					<Link href={'https://www.thenewhumanitarian.org/investigations/2021/10/27/aid-sector-carbon-footprint-environmental-impact'}>TNH investigation</Link>{' '}
					into the carbon footprint of the humanitarian aid sector, and related podcast episode, gave “many thoughts on how and where donors could influence
					organisations or donor groups”, one government donor told us. They described it as pushing back against the tendency to simply seek the cheapest
					approach to providing aid even if it isn’t the most environmentally friendly or sustainable option – and instead exploring value-for-money over a
					longer time horizon.
				</p>

				<InlineVideo
					fileName={'co2-video_1.mp4'}
					autoPlay
					loop
					muted
					caption={
						<span>
							The world’s largest aid organisations are{' '}
							<Link
								className={'font-bold'}
								href={'https://www.thenewhumanitarian.org/investigations/2021/10/27/aid-sector-carbon-footprint-environmental-impact'}
							>
								divided when it comes to which greenhouse gas emissions to measure
							</Link>{' '}
							and how, according to a survey by The New Humanitarian that painted a messy picture of greening efforts in the sector and exposes gaps in tracking
							data.
						</span>
					}
				/>

				{/* <InlineImage fileName={'our-impact-inline-three.jpg'} caption={'Caption for this image (TNH)'} /> */}
				<p>
					From the politicisation of aid in Ethiopia to the lack of neutrality of NGOs in Afghanistan, the podcast “has really made me feel reinvigorated to
					search out the new potential directions for this industry", said one listener. “TNH really provides a forum for stimulating forward-thinking in
					humanitarian affairs. We need that very much,” said Michael Köhler, the deputy director of the European Commission’s humanitarian aid arm, ECHO.
				</p>
				<p>
					Our coverage of the challenges facing the aid industry is regularly cited as a resource, including for example by the The Racial Equity Index, and we
					regularly share our findings in private briefings, interviews, and events. One such talk to a group of policymakers was described as “interesting,
					provocative and mind-boggling”, and completely different to what donors hear in their usual discussions. In another, a donor representative said, in
					reaction to our analysis: “My mind is kind of blown.” Another added: “These issues are on our minds, but we haven’t connected the dots in this way.”
				</p>
				<h2>Anatomy of the investigation that rocked the WHO</h2>
				<p>
					In September 2020, a{' '}
					<Link href={'https://www.thenewhumanitarian.org/2020/09/29/exclusive-more-50-women-accuse-aid-workers-sex-abuse-congo-ebola-crisis'}>
						year-long investigation
					</Link>{' '}
					by The New Humanitarian, co-published with the{' '}
					<Link href={'https://longreads.trust.org/item/congo-ebola-aid-workers-accused-demanding-sex-for-jobs'}>Thomson Reuters Foundation</Link> uncovered
					claims by more than 50 Congolese women of sexual abuse and exploitation by aid workers in the eastern city of Beni during the 2018 to 2020 Ebola
					crisis in the Democratic Republic of Congo. In May 2021, we followed up with{' '}
					<Link href={'https://www.thenewhumanitarian.org/2021/05/12/exclusive-new-sex-abuse-claims-against-ebola-aid-workers-exposed-congo'}>
						another investigation
					</Link>
					, this time focused on the nearby city of Butembo, uncovering claims by a further 22 women of sexual abuse and exploitation. Many of the alleged
					abusers were working for the World Health Organization (WHO).
				</p>
				<p>
					Together, these investigations have been among the most impactful stories published in The New Humanitarian’s 26-year history. Because of our work,
					aid organisations have initiated internal investigations, the UK’s International Development Committee has called on the government to crack down on
					UK-funded organisations accused of sexual abuse, and aid groups have started recruiting more people tasked with preventing sexual exploitation.
				</p>
				<p>
					Our investigations triggered the creation of an independent commission to investigate the claims; a public apology to victims by the director-general
					of the WHO, and a vow to work towards a profound transformation of the organisation’s culture.{' '}
				</p>
				<InlineImage wide fileName={'annual-report-timeline.jpg'} />
			</div>
		),
	},
	{
		title: 'Connecting with our audience',
		id: 'connecting-with-our-audience',
		backgroundImage: 'connecting-with-our-audience',
		className: 'bg-gray-300',
		htmlContent: (
			<ChapterHeader
				textShadow
				title={'Connecting with our audience'}
				subtitle={'Finding new ways to engage our readers.'}
				quote={['Finding new ways to engage our readers.']}
			/>
		),
		fullContent: (
			<>
				<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
					<InlineTypist text={['Finding new ways to engage our readers.']} />
					<p>
						In 2021, our average monthly web users settled after the dramatic peaks in 2020 attributed to our coverage of the COVID-19 pandemic. On average, we
						had 278,987 monthly visitors to our website, with a high of 377,575 in April, after our investigation into new Oxfam misconduct allegations in Iraq
						and “rotten” work culture in Congo.
					</p>
					<p>
						In total, articles on our site were read more than 5 million times by more than 3 million people in 2021. New articles were viewed 4,335 times on
						average, a decrease compared to a year dominated by pandemic stories in 2020, but a more than 50 percent increase on average page views per article
						in 2019.
					</p>
					<p>
						We also ramped up our efforts to provide a platform for diverse voices from across the humanitarian spectrum to have their say, with more than 15
						widely read opinion pieces, two of which – about volunteerism and child sponsorship respectively – reached more than 10,000 page views and inspired
						lively debate on Twitter.
					</p>
					<p>
						Our online community grew as we saw our reach on social media increase in 2021. Our videos were viewed more than half a million times on YouTube,
						with an additional 78,000 video views on other social channels. Our audience on social media increased by 12 percent to 188,000 followers, while our
						newsletter subscribers grew by 8,000 to total more than 64,000, and our podcasts were downloaded more than 100,000 times.
					</p>
					<h2 className={'mb-8'}>In their words...</h2>
					<InlineQuote
						text={
							'"We have been impressed with how The New Humanitarian (in particular, though of course other agencies have also done great work) has worked so diligently to bring forward sexual harassment, exploitation and abuse in the humanitarian field.”'
						}
						person={'Helena Minchew, Advocacy Advisor, Women’s Protection and Empowerment and Gender Equality, International Rescue Committee'}
					/>
					<InlineQuote
						text={
							'“The pieces that are coming out of TNH are just amazing, especially given the way you are incorporating multimedia, amplifying local voices, utilising excellent photography, and weaving those personal stories in through more technical analysis. It is really, really impressive, all in spite of the obvious challenges to getting reporters on the ground.”'
						}
						person={' Devon Terrill, Program Officer, Journalism & Media, Stanley Center for Peace and Security'}
					/>
					<InlineQuote
						text={'“TNH really provides a forum for stimulating forward thinking in humanitarian affairs. We need that very much.”'}
						person={'Michael A. Köhler, Acting Director-General, European Commission'}
					/>
					<h2>On the global stage</h2>
					<p>
						We continued hosting online events in 2021 to deepen engagement with our readers. We tried a fresh format for our flagship 10 Crises to Watch event,
						inviting leading thinkers from across the humanitarian space to speak about the issues that had their attention at the turn of the year. More than
						1,000 people joined us for an engaging conversation on the trends on the horizon.
					</p>
					<InlineYoutubeVideo
						src={'https://www.youtube.com/embed/dhG9j6mQblQ'}
						caption={
							<span>
								<a className={'font-bold'} href={'https://www.youtube.com/embed/dhG9j6mQblQ'}>
									Click here
								</a>{' '}
								to watch the full video on Youtube.
							</span>
						}
					/>
					{/* <div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}> */}
					<p>
						We also organised a series of Reader Salons for more informal conversations with editors from The New Humanitarian. Readers got a chance to hear
						from journalists like Eric Reidy, our editor-at-large for migration, who spoke in-depth about his reporting, after winning the UN Correspondents
						Association for his work reporting on the impact of COVID-19 on displaced people.
					</p>
					<InlineImage
						fileName={'connecting-with-our-audience-inline.jpg'}
						caption={`Eric Reidy, migration editor-at-large, receives the UN Correspondents Association Elizabeth Neuffer Memorial
Prize for his reporting on the impact of COVID-19 on migrants and refugees. (Eric Reidy/TNH)`}
					/>
					<h2>Raising our profile</h2>
					<p className={'pb-20'}>
						The quality of our journalism continued to make us an attractive partner for major newsrooms around the world, who worked with us to ensure the
						stories we told reached as wide and relevant an audience as possible. We co-published reports with the{' '}
						<Link href={'https://news.trust.org/item/20210512055241-9luih/'}>Thomson Reuters Foundation</Link>,{' '}
						<Link href={'https://www.independent.co.uk/independentpremium/long-reads/burundi-poorest-country-africa-genocide-b1919095.html?r=49614'}>
							The Independent
						</Link>
						, <Link href={'https://www.aljazeera.com/news/2021/4/7/corruption-claims-spark-new-concerns-about-aid-to-south-sudan'}>Al Jazeera</Link>,{' '}
						<Link href={'https://www.theguardian.com/us-news/2021/oct/26/native-american-groups-record-donations-covid'}>The Guardian US</Link>, and{' '}
						<Link
							href={
								'https://www.spiegel.de/international/world/france-s-war-in-west-africa-people-collected-severed-arms-legs-and-heads-a-ae781f0b-a043-4d0e-9c8d-66491341e176?utm_source=dlvr.it&utm_medium=twitter#ref=rss'
							}
						>
							Der Spiegel
						</Link>
						.
					</p>
					<div className={'gap-10 grid grid-cols-1 sm:grid-cols-3 justify-center items-start my-20 sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}>
						<Link href={'https://www.theguardian.com/us-news/2021/oct/26/native-american-groups-record-donations-covid'}>
							<div className={'flex items-center justify-center flex-col text-left shadow-lg hover:shadow-xl'}>
								<BlockImage fileName={'connecting-with-our-audience-inline-two.jpg'} caption={'Caption for this image (TNH)'} />
								<p className={'p-2 bg-gray-50'}>Native American groups capitalize on record donations to confront chronic hardship</p>
							</div>
						</Link>
						<Link href={'https://www.aljazeera.com/news/2021/4/7/corruption-claims-spark-new-concerns-about-aid-to-south-sudan'}>
							<div className={'flex items-center justify-center flex-col text-left shadow-lg hover:shadow-xl'}>
								<BlockImage fileName={'connecting-with-our-audience-inline-three.jpg'} caption={'Caption for this image (TNH)'} />
								<p className={'p-2 bg-gray-50'}>Corruption claims spark new concerns about aid to South Sudan</p>
							</div>
						</Link>
						<Link href={'https://www.independent.co.uk/independentpremium/long-reads/burundi-poorest-country-africa-genocide-b1919095.html?r=49614'}>
							<div className={'flex items-center justify-center flex-col text-left shadow-lg hover:shadow-xl'}>
								<BlockImage fileName={'connecting-with-our-audience-inline-four.jpg'} caption={'Caption for this image (TNH)'} />
								<p className={'p-2 bg-gray-50'}>Will digging up mass graves in troubled Burundi reopen old wounds?</p>
							</div>
						</Link>
					</div>
					<p>
						Our work was republished, cited by, or linked to from numerous major media outlets, as well as diaspora newspapers, including The New York Times,
						The Washington Post, National Public Radio, The Guardian, Swiss Info, Le Monde, The Mail & Guardian, The Haitian Times, Nigerian Tribune, The South
						African, and Jemen-Report, an annual report on Yemen published by the German-Yemeni society.
					</p>
					<p>
						Our staff were also interviewed by the BBC, Voice Of America, France 24, and TV5 Monde, among others, and in podcasts including{' '}
						<Link href={'https://soundcloud.com/fhi360/confronting-dilemmas-in-humanitarian-response'}>Deeper Look</Link>,{' '}
						<Link href={'https://www.aljazeera.com/podcasts/2021/4/7/exclusive-south-sudan-accused-of-covid-19-corruption'}>Al Jazeera’s The Take</Link>,{' '}
						<Link href={'https://itsalljournalism.com/new-humanitarians-mission-based-approach-could-be-model-for-newsrooms/'}>It’s All Journalism</Link>, and
						<Link href={'https://www.storytellingforimpact.net/5-do-no-harm-also-applies-to-journalism-thin-lei-win-humanitarian-correspondent/'}>
							Storytelling for Impact
						</Link>
						.
					</p>
					<InlineAudio
						externalLinkText={'Listen on Soundcloud'}
						externalLink={'https://soundcloud.com/fhi360/confronting-dilemmas-in-humanitarian-response'}
						fileName={'Deeper-Look-Aly-v4.mp3'}
					/>
					<p>
						As part of our efforts to build our visibility and clout within the media sector, we gave keynote speeches at the One World Media Summit and{' '}
						<Link href={'https://youtu.be/YsuA26MGfi4?t=5029'}>Human Rights Film Festival</Link> Berlin, we hosted sessions at the Online News Association’s
						annual conference and the African Investigative Journalism Conference. At the{' '}
						<Link href={'https://www.youtube.com/watch?v=411NoEyaQ6k&t=7s&ab_channel=DWGlobalMediaForum'}>DW Global Media Forum</Link>, CEO Heba Aly kicked off
						our initiative on decolonising journalism with a fireside chat with Kenyan political analyst and media critic Patrick Gathara that explored why
						international media coverage of the Global South needs to be decolonised; what that looks like; and the challenges associated with it.
					</p>
					<div className={'relative w-full h-0 pt-sixteenbynine mb-16'}>
						<iframe
							className={'my-8 absolute top-0 left-0 w-full h-full'}
							width='100%'
							height='auto'
							src='https://www.youtube.com/embed/V6zZydNV7sU'
							title='YouTube video player'
							frameborder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullscreen
						></iframe>
					</div>
					<h2>Our membership programme</h2>
					<p>
						We saw growth in our membership programme, which provides a channel for readers who are aligned with our mission to support us financially, while
						also bringing them closer to our work. By the end of 2021, 637 readers were paying members who contributed more than 41,000 CHF to our work
						throughout the year.
					</p>
					<p>
						This is an important part of our plans to diversify our revenue, decrease reliance on grants, and increase our independence. Membership provides us
						with an opportunity to keep our most loyal supporters informed about our work, including at a strategic level, via a members-only newsletter, a
						private Slack channel, and our Reader Salons, where they engage in informal conversations with TNH editors about our stories.
					</p>
					<h2 className={'text-center mb-8'}>
						Want to get involved and support our journalism?
						<br />
						<Link href={'https://www.thenewhumanitarian.org/membership'}>
							<span className={'text-burgundy'}>Become a member today.</span>
						</Link>
					</h2>
					<InlineQuote
						text={
							'“With our support, I hope to see The New Humanitarian will continue apace – reporting from a crisis, pushing the humanitarian aid sector to improve and innovate, and giving someone like me, at my breakfast table in Tennessee, critical analysis of conflict, migration and disaster activity globally.”'
						}
						person={'Regine A. Webster, member of The New Humanitarian'}
					/>
				</div>
			</>
		),
	},
	{
		title: 'Funding and governance',
		id: 'funding-and-governance',
		backgroundImage: 'funding-and-governance',
		className: 'bg-gray-200',
		htmlContent: (
			<ChapterHeader
				textShadow
				title={'Funding and governance'}
				subtitle={'The foundations that position us for success.'}
				quote={['The foundations that position us for success.']}
			/>
		),
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['The foundations that position us for success.']} />
				<p>
					In 2021, our overall income was CHF 2,976,695 and our total expenditure was CHF 2,178,390. This represents a 10 percent increase in our spending
					levels compared to 2020, with 20 partners (<Link href={'https://www.thenewhumanitarian.org/content/about-us#our-supporters'}>listed here</Link>)
					supporting our work.
				</p>
				<p>
					Unearmarked funding, which accounted for 70 percent of our overall income, allowed more coherent coverage and flexibility to adapt to a changing news
					environment. Through individual donations, speaker fees, and our membership programme, we generated small amounts of independent revenue, allowing us
					to continue building reserves and providing greater sustainability for our organisation.
				</p>
				<h2>Our organisation and governance </h2>
				<p>
					In 2021, TNH established a full operations team to strengthen its foundation for future organisational development and growth. In November, we hired
					our first Chief Operating Officer (COO) to lead strategic and risk management processes.
				</p>
				<p>
					She oversees a Head of People and Culture, who is tasked with building a diverse, global team and nurturing a culture of innovation and well-being; as
					well as a Head of Administration, Finance and Compliance, who manages the organisation’s finances. They are supported by an Admin and Finance
					Assistant. The operations team will support the organisation in its next phase of development to mature its internal processes, increase financial
					transparency, improve employee experience, and scale its work.{' '}
				</p>
				<p>
					We hired a podcast producer to reach and engage target audiences through our flagship Rethinking Humanitarianism podcast, while also developing future
					podcast offerings. We also brought on board a multimedia editor to use video to amplify our journalism to wider audiences.
				</p>
				<p>
					We equally welcomed some new faces to our board of directors: award-winning Syrian journalist Zaina Erhaim; American marketing and product development
					executive Hayley Nelson; Chilean editor and author Paula Escobar-Chavarría; and award-winning Indian media entrepreneur Syed Nazakat.
				</p>
			</div>
		),
	},
	{
		title: "What's next",
		id: 'what-next',
		backgroundImage: 'what-next',
		className: 'bg-gray-300',
		htmlContent: <ChapterHeader textShadow title={'What’s next?'} subtitle={'Looking to the future.'} quote={['Looking to the future. ']} />,
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['Looking to the future.']} />
				<p>
					The current journalistic model is broken: Audiences are demanding that the hierarchical, elite-led system of news-gathering and presentation be
					dismantled in favour of a more inclusive and holistic model based on more equitable access to information and more nuanced and diverse narratives.
				</p>
				<p>
					The business model is also broken, with many media going bankrupt during the pandemic – despite their information being more valuable than ever –
					because of a dependence on advertisers.
				</p>
				<p>Finally, exploitative and extractive practices have long been commonplace in media and other businesses.</p>
				<h2>We think there is a better way. We want to build something different.</h2>
				<p>
					Our new five-year strategy outlines how we will do so. It is an ambitious vision to become a transformative newsroom – and one that we need your
					support to achieve.
				</p>
				<ul className={'font-bold py-8'}>
					<li className={'pb-8'}>
						<p>
							First, we will decolonise our journalism – by being more inclusive of and guided by the communities we serve – so that we better represent the
							issues that matter to them.
						</p>
					</li>
					<li className={'pb-8'}>
						<p>
							Second, we will centralise impact in our work – by proactively mapping and reaching out to the audiences that can be served by a given story – so
							that every story finds the audience that can use it to drive change.
						</p>
					</li>
					<li className={'pb-8'}>
						<p>
							Third, we will strengthen our newsroom – by investing in key editorial functions and support systems – so that we can more consistently report
							from the heart of crises.
						</p>
					</li>
					<li className={'pb-8'}>
						<p>
							Fourth, we will diversify our income – by growing grant funding and developing new revenue streams – so that our journalism remains independent
							and sustainable.
						</p>
					</li>
					<li className={'pb-8'}>
						<p>
							Finally, we will nurture an organisational culture of excitement, innovation, and well-being so that staff feel valued, proud, and motivated to do
							their best work.
						</p>
					</li>
				</ul>
				<p>
					In the age of misinformation and dwindling business models, producing quality journalism is already a feat. Doing so sustainably and ethically – even
					more so.{' '}
				</p>
				<p>
					Our new strategy sees us continuing this important work while embracing the best of what journalism can be and shedding its more problematic
					qualities.
				</p>
				<p>
					You can support us on this journey{' '}
					<Link href={'https://www.thenewhumanitarian.org/membership'}>by becoming a member of The New Humanitarian today.</Link>
				</p>
			</div>
		),
	},
]

export default CHAPTERS
